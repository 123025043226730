
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import TableView from "@views/page/components/table-view.vue";
import FilterModel from "../components/filter-model";
import getUTC from "@/function/getUTC";
import EndpointLogsItemResponse from "@/dataSource/api/logger-micro-service/services/models/response/log-getter-service/EndpointLogsItemResponse";
import PageResponse from "@/dataSource/api/pagination-helper/lib/PageResponse";
import EnumLogLevel from "@/dataSource/api/tirscript/logger-micro-service/enums/EnumLogLevel";
import GetTextLogsItem from "@/dataSource/api/logger-micro-service/services/models/response/GetTextLogsItem";
import GetTextLogsRequest from "@/dataSource/api/logger-micro-service/services/models/request/GetTextLogsRequest";
import GetTextLogsResponse from "@/dataSource/api/logger-micro-service/services/models/response/GetTextLogsResponse";

@Options({
  name: "TextLogsView",
  components: {
    TableView,
  },
})
export default class LogsView extends Vue {
  @Prop({ default: new FilterModel() }) filter: FilterModel;

  innerFilter: GetTextLogsRequest;
  items: GetTextLogsItem[] = [];
  pageResponse: PageResponse = new PageResponse();

  takePage: number = 20;
  skipPage: number = 0;

  noPagination = false;
  isLoading = false;

  created() {
    if (this.filter && (this.filter.SkipPage || this.filter.SkipPage == 0)) {
      this.skipPage = this.filter.SkipPage;
    }
  }

  type(item: EndpointLogsItemResponse) {
    switch (item.LevelId) {
      case EnumLogLevel.Error:
        return "error";
      case EnumLogLevel.Warn:
        return "warn";
      default:
        return "default";
    }
  }

  onFilterChange() {
    this.skipPage = 0;
    if (this.$refs.pagination) {
      (this.$refs.pagination as any).reset();
    }
    this.refresh();
  }

  mounted() {
    if (this.$route.name == "text-logs-view") {
      this.refresh();
    }
  }

  // методы логов
  async refresh() {
    if (!this.filter) {
      return;
    }

    let response: GetTextLogsResponse = null;
    this.isLoading = true;
    this.innerFilter = {
      Search: this.filter.ShortMessage,
      LoggerName: this.filter.LoggerName,
      LevelId: this.filter.LogLevel,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Page: { Skip: this.skipPage, Take: this.takePage },
    };

    let tmpFilter = getUTC(this.innerFilter);
    try {
      response = await this.$api.LogGetterService.getTextLogsAsync(tmpFilter);
    } catch (error) {
      console.log(`error:`, error);
      this.isLoading = false;
    }
    this.isLoading = false;

    this.pageResponse = response.Page;

    this.items = response.List.map((item: any) => ({
      RequestId: item.RequestId,
      DateCreated: item.DateCreated,
      Message: item.Message,
      LoggerName: item.LoggerName,
      LevelId: item.LevelId,
      ProjectLogGroupId: item.ProjectLogGroupId,
    }));

    if (this.$refs.pagination) {
      (this.$refs.pagination as any).currentPage = this.skipPage / this.takePage + 1;
    }
  }

  // событие срабатывет при изменении пагинации при просмотре
  onPaginationChanged(page: number) {
    let skip = (page - 1) * this.takePage; // текущая страница * кол-во записей на странице
    if (this.skipPage === skip) {
      return;
    }

    this.skipPage = skip;
    this.updateQuery();

    this.refresh();
  }

  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }

  updateQuery() {
    this.$emit("updateQuery", this.skipPage);
  }
}
